<template>
  <div
    class="
      demo-pagination-block
      d-flex
      justify-content-between
      align-items-center
    "
  >
    <span>
      Showing {{ start }} to {{ endPaginationIndex }} of {{ totalPage }} results
    </span>
    <div>
      <el-pagination
        :class="customClassPagination"
        background
        :currentPage="currentPage"
        :page-sizes="[10, 20, 50]"
        :layout="layout"
        :total="totalPage"
        :page-size="totalPageSize"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script lang="ts">
import { computed } from "@vue/runtime-core";
import { number } from "@intlify/core-base";
export default {
  props: {
    totalPageSize: {},
    totalPage: {},
    startPaginationIndex: {
      default: -1,
    },
    endPaginationIndex: {
      default: 0,
    },
    pageSizes: {
      required: false,
    },
    isPageSizeChange: {
      required: false,
      default: false,
    },
    currentPage: {
      required: true,
      default: 1,
    },
    customClassPagination: {
      default: "",
    },
  },
  emits: ["raise-size-change", "raise-page-change"],
  setup(props, { emit }) {
    const handleSizeChange = (value) => {
      emit("raise-size-change", value);
    };

    const handlePageChange = (value) => {
      emit("raise-page-change", value);
    };

    // start index
    const start = computed(() => {
      if (props.startPaginationIndex === -1) {
        return props.startPaginationIndex + 2;
      } else if (props.endPaginationIndex === 0) {
        return props.startPaginationIndex;
      } else {
        return props.startPaginationIndex + 1;
      }
    });
    const layout = computed(() => {
      const addSize = props.isPageSizeChange ? "sizes, " : "";
      return `${addSize}prev, pager, next`;
    });

    return {
      start,
      layout,
      handleSizeChange,
      handlePageChange,
    };
  },
};
</script>
<style scoped>
.ml-auto {
  margin-left: auto;
}
.el-pager li {
  color: #90a4ae !important;
}
</style>
