import PaginationType from "@/core/types/misc/Pagination";
import { FilterData } from "@/core/types/misc/FilterData";
import { defineStore } from "pinia";
import {
  ApplicationItem,
  MedicalServiceApplication,
  ReportItem,
  ReportTransactionDetailItem,
  TransactionItem,
} from "@/core/types/gws-global-feature/cpmi/MedicalServiceApplication";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";
import { ListData } from "@/core/types/gws-master/master/medical-services/informed-consent/ListData";
import { ListData as DoctorItem } from "@/core/types/gws-medical/doctor-management/ListItem";
import { ScreeningItem } from "@/core/types/gws-medical/digital-data/ScreeningItem";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { Option } from "@/core/types/misc/Option";
import { AdvFiltering } from "@/core/types/misc/AdvFiltering";

const authStore = useAuthenticationStore();
export const useMedicalReportListStore = defineStore({
  id: "medicalReportListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "ASC",
        sort: "created_at",
      } as FilterData,
      stateAdvFiltering: {
        dateRange: [] as string[],
      } as AdvFiltering,
      statePagination: new PaginationType(),
      stateDisplayData: [] as ReportItem[],
      stateDisplayDataReportTransaction: [] as TransactionItem[],
      stateInformedConsent: [] as ScreeningItem[],
      stateDoctorOption: [] as Option[],
      stateCpmiApplicationData: [] as ApplicationItem[],
      stateReportTransactionDetail: [] as ReportTransactionDetailItem[],
      stateAgentId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    displayDataReportTransaction(state) {
      return state.stateDisplayDataReportTransaction;
    },
    displayDataReportTransactionDetail(state) {
      return state.stateReportTransactionDetail;
    },
    informedConsent(state) {
      return state.stateInformedConsent;
    },
    doctors(state) {
      return state.stateDoctorOption;
    },
    cpmiMedicalApplication(state) {
      return state.stateCpmiApplicationData;
    },
    agentId(state) {
      return state.stateAgentId;
    },
    advFiltering(state) {
      return state.stateAdvFiltering;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      this.stateIsLoading = true;
      this.stateDisplayData = [];
      let adv = "";
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      if (this.advFiltering.dateRange.length > 0) {
        adv += `created_at:gte:${this.advFiltering.dateRange[0]};created_at:lte:${this.advFiltering.dateRange[1]};`;
      }
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      authStore.getUserInfo();
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<MedicalServiceApplication>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical-service-application/${
              authStore.user.org_id
            }/report-requester?${new URLSearchParams(params).toString()}${
              adv ? `&adv_filter=` + adv : ""
            }`
          );
        this.stateDisplayData =
          response.data.data.medical_report_application_requester;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error: any) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getReportTransaction(isPageRefresh = true) {
      this.stateIsLoading = true;
      this.stateDisplayData = [];
      let adv = "";
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
      };
      if (this.advFiltering.dateRange.length > 0) {
        adv += `created_at:gte:${this.advFiltering.dateRange[0]};created_at:lte:${this.advFiltering.dateRange[1]};`;
      }
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      authStore.getUserInfo();
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<MedicalServiceApplication>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical-service-application/${
              authStore.user.org_id
            }/report-transaction?${new URLSearchParams(params).toString()}${
              adv ? `&adv_filter=` + adv : ""
            }`
          );
        this.stateDisplayDataReportTransaction =
          response.data.data.medical_report_application_transaction;
      } catch (error: any) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getReportTransactionDetail(isPageRefresh = true, serviceDate) {
      this.stateIsLoading = true;
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        agent_id: this.stateAgentId,
        service_date: serviceDate,
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      authStore.getUserInfo();
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<MedicalServiceApplication>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical-service-application/${
              authStore.user.org_id
            }/report-transaction-detail?${new URLSearchParams(
              params
            ).toString()}`
          );
        this.stateReportTransactionDetail =
          response.data.data.medical_report_application_transaction_detail;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error: any) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getLookupInformedConsent() {
      this.stateInformedConsent = [];
      const params = {
        page: "1",
        limit: "99",
        dir: "DESC",
        sort: "created_at",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        const response: AxiosResponse<ApiResponse<ListData[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/consents?${new URLSearchParams(params).toString()}`
          );
        const consents = response.data.data;
        consents.forEach((el) => {
          this.stateInformedConsent.push({
            type: el.type,
            question: el.name,
            answer: "",
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getLookupDoctor(isPageRefresh = true) {
      this.stateDoctorOption = [];
      this.stateIsLoading = true;
      authStore.getUserInfo();
      const params = {
        page: "1",
        limit: "99",
        dir: "DESC",
        sort: "created_at",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<DoctorItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `medical/facilities/${
              authStore.user.org_id || ""
            }/doctor?${new URLSearchParams(params).toString()}`
          );
        const doctors = response.data.data;
        doctors.forEach((el) => {
          this.stateDoctorOption.push({
            label: el.name,
            value: el.id,
          });
        });
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
    setAgentId(id: string) {
      this.stateAgentId = id;
    },
  },
});
